import React, { FC, ReactNode } from 'react';

// components
import Button from 'components/Button';
import Stack from 'components/Stack';

// assets
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/solid';

interface IModalMessage {
  variant?: 'error' | 'warning' | 'success';
  message: string | ReactNode;
  buttonText?: string;
  close: (x: boolean) => void;
}

const variants = {
  error: <ExclamationCircleIcon className="h-16 w-16 text-red-400" />,
  warning: <ExclamationIcon className="h-16 w-16 text-yellow-400" />,
  success: <CheckCircleIcon className="h-16 w-16 text-green-400" />
};

const ModalMessage: FC<IModalMessage> = ({
  variant = 'error',
  message,
  buttonText = 'Ok',
  close
}) => {
  return (
    <div className="p-5 space-y-5 text-center">
      <div className="flex justify-center">{variants[variant]}</div>
      <div className="text-lg text-hazel-green-400">{message}</div>
      <Stack className="justify-center">
        <Button
          type="button"
          className="w-32"
          variant="green-primary"
          rounded="full"
          classNameButton="justify-center uppercase"
          onClick={() => close(false)}
        >
          {buttonText}
        </Button>
      </Stack>
    </div>
  );
};

export default ModalMessage;
